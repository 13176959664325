import React from 'react'
import Layout from 'components/layout';
import c from 'classnames';

import wrappers from 'styles/_wrappers.module.scss';
import helpers from 'styles/_helpers.module.scss';

const TermsAndConditions = props => (
  <Layout title="Termos e Condições" location={props.location}>
    <div className={c(wrappers.wrapper, helpers.center)}>
      <h1 className={helpers.mt20}>Termos e Condições</h1>
      <p className={helpers.mt20}>
        LEIA CUIDADOSAMENTE ESTES TERMOS DE USO ANTES DE UTILIZAR OS SERVIÇOS
        OFERECIDOS PELA REDLIGHT SOFTWARE (&quot;CPC ANOTADO&quot;). ESTES
        TERMOS DE USO ESTABELECEM OS TERMOS E CONDIÇÕES OBRIGATÓRIOS POR LEI
        PARA USO DO SITE NO ENDEREÇO WWW.DIREITOANOTADO.COM.BR E DOS SERVIÇOS,
        RECURSOS, CONTEÚDO, APLICATIVOS OU WIDGETS OFERECIDOS PELO CPC ANOTADO
        (COLETIVAMENTE COM O SITE, O &quot;SERVIÇO&quot;).
      </p>

      <p className={helpers.mt20}>Aceitação dos termos</p>
      <p className={helpers.mt20}>
        Ao se registrar e/ou usar o Serviço de toda e qualquer forma, incluindo,
        mas não se limitando a, visitar ou utilizar o Site, você concorda com
        todos os termos e condições contidos aqui (&quot;Termos de uso&quot;),
        que também incorporam a Política de privacidade do CPC ANOTADO, Política
        de propriedade intelectual do CPCANOTADO, Termos e condições do
        estabelecimento do CPC ANOTADO e todas as outras regras de operação,
        políticas e procedimentos que podem ser publicados de tempos em tempos
        no Site pelo DIREITO ANOTADO, do qual cada um é incorporado por
        referência e pode ser atualizado pela REDLIGHT SOFTWARE de tempos em
        tempos, sem avisos, de acordo com os termos estabelecidos na seção
        &quot;Modificação dos Termos & Condições&quot; abaixo. Alguns serviços
        oferecidos pelo Serviço podem estar sujeitos a termos e condições
        adicionais especificados pelo DIREITO ANOTADO de tempos em tempos; o uso
        de tais serviços está sujeito aos termos e condições adicionais, que são
        incorporadas nestes Termos de uso através desta referência. Estes Termos
        de uso se aplicam a todos os usuários do Serviço, incluindo, mas não se
        limitando a, usuários colaboradores de conteúdo, informação e outros
        materiais ou serviços no Site, usuários individuais do Serviço,
        estabelecimentos que acessam o Serviço e usuários que possuem uma página
        no Serviço.
      </p>
      <p className={helpers.mt20}>Links – Ligações de Hipertexto</p>
      <p className={helpers.mt20}>
        A REDLIGHT SOFTWARE não se responsabiliza pela qualidade ou veracidade
        das informações facultadas nos websites para onde se remetem as ligações
        de hipertexto, também denominadas por “hyperlinks” ou “links” presentes
        neste Site. A REDLIGHT SOFTWARE não exerce qualquer controle sobre os
        conteúdos, produtos e/ou serviços oferecidos por terceiros através dos
        links inseridos na mesma.
      </p>
      <p className={helpers.mt20}>Confidencialidade</p>
      <p className={helpers.mt20}>
        A informação recolhida sobre os visitantes do Site é de carácter
        pessoal, pelo que se assegura a confidencialidade da identidade do
        visitante. Haverá, todavia lugar a identificação dos utilizadores sempre
        que cometem atos ilegais dentro do Site, nomeadamente, tentativas de
        alteração de informação, de carregamento de informação, ou qualquer
        outra ação que possa causar dano e pôr em risco a integridade do
        sistema, ou quando tal for solicitado por decisão judicial. A REDLIGHT
        SOFTWARE compromete-se a tomar todas as medidas necessárias para evitar
        perdas, má utilização ou alteração da informação recebida dos
        visitantes. No caso de o visitante enviar uma mensagem ao Site, o seu
        endereço não será divulgado, exceto se tal for solicitado por decisão
        judicial.
      </p>
      <p className={helpers.mt20}>Acesso</p>
      <p className={helpers.mt20}>
        A REDLIGHT SOFTWARE reserva-se ao direito de impedir, de condicionar o
        acesso ou alterar as condições do mesmo por parte de algum utilizador.
      </p>
      <p className={helpers.mt20}>Modificações de Termos & Condições</p>
      <p className={helpers.mt20}>
        A alteração das presentes condições de utilização ou mesmo, em última
        instância, a remoção do Site poderão ser efetuadas a todo o tempo e
        produzem efeitos imediatos sem necessidade de comunicação prévia aos
        utilizadores.
      </p>
      <p className={helpers.mt20}>Lei Aplicável</p>
      <p className={helpers.mt20}>
        As presentes condições de utilização regem-se pela lei brasileira e o
        Site foi criado e funciona em conformidade com essa lei. Para quaisquer
        litígios relacionados com o Site e a sua utilização são exclusivamente
        competentes os tribunais brasileiros, elegendo as partes como
        territorialmente competentes, com expressa renúncia a quaisquer outros,
        os tribunais judiciais da comarca de Fortaleza/CE.
      </p>
    </div>
  </Layout>
);
export default TermsAndConditions;
